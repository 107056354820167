.error-page {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .error-icon {
        margin: 0.8em;
    }
}
