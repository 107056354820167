@import "index";

.case-studies {
    display: flex;
    flex-direction: column;
    align-items: center;

    > div,
    > ul {
        width: 90%;
    }

    .case-studies-header-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: lightblue;
        margin-bottom: 3em;

        @media (max-width: $breakpoint-mobile) {
            min-height: 4em;
        }

        .case-studies-header {
            width: 90%;
            margin-top: 3.5em;
            height: 45vh;
            min-height: 28.6em;

            @media (max-width: $breakpoint-tablet) {
                min-height: 21.5em;
                height: fit-content;
            }

            @media (max-width: $breakpoint-mobile) {
                height: 17.9em;
                min-height: 15em;
                align-items: center;
            }
        }
    }

    .case-studies-tools {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: fit-content;
        align-items: flex-end;
        padding: 2em;
        width: 90%;

        .search-tool {
            width: 100%;
            padding-bottom: 1em;
        }
    }

    .case-studies-list-wrapper {
        display: flex;
        justify-content: center;
        height: fit-content;
        width: 90%;

        // .categories-list {
        //     padding: 0;
        //     margin: 0;
        //     list-style: none;
        //     width: 20%;
        //     margin: 3em 0 3em 0;
        //     padding: 3em 2em 3em 2em;

        //     li {
        //         font-family: $main-font;
        //         font-size: 16px;
        //         font-weight: $bold;
        //         padding: 0.25em;
        //         letter-spacing: 0.25px;
        //         cursor: pointer;

        //         &.active {
        //             color: #5ebdcd;
        //         }
        //     }
        // }

        .case-studies-list {
            padding: 0;
            margin: 0;
            list-style: none;
            width: 100%;
            padding: 1vh 0 1vh 0;

            .case-study-item {
                height: fit-content;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 2em 0 2em 0;

                &:not(:last-child) {
                    border-bottom: 0.5px solid rgba(111, 112, 141, 0.6);
                }

                a {
                    margin: 1em;
                    padding: 1em 1em 2em 1em;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    height: fit-content;

                    @media (max-width: $breakpoint-tablet) {
                        align-items: center;
                        flex-direction: column;
                    }

                    .study-img {
                        width: 50%;
                        max-width: 28.5em;
                        height: 20vw;
                        min-height: 20em;
                        max-height: 22em;
                        background-size: auto 100%;
                        background-position: top;
                        background-repeat: no-repeat;

                        @media (max-width: $breakpoint-tablet) {
                            margin-bottom: 2em;
                            background-size: 100%;
                            width: 100%;
                            height: 30vw;
                            max-width: none;
                            min-height: none;
                        }

                        @media (max-width: $breakpoint-mobile) {
                            background-size: cover;
                        }
                    }

                    .description {
                        width: 100%;
                        margin-left: 3em;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        @media (max-width: $breakpoint-tablet) {
                            margin-left: 0;
                        }

                        .description-title {
                            font-family: $main-font;
                            font-weight: $extra-bold;
                            color: #191a2e;
                            font-size: 2.5vw;
                            line-height: 2.5vw;
                            letter-spacing: -0.5px;

                            @media (min-width: 1250px) {
                                font-size: 2em;
                                line-height: 1.35em;
                            }

                            @media (max-width: $breakpoint-tablet) {
                                font-size: 2em;
                                line-height: 1.35em;
                            }
                        }
                        .meta {
                            display: flex;
                            justify-content: space-between;
                            height: fit-content;

                            @media (max-width: $breakpoint-tablet) {
                                flex-direction: column;
                                align-items: flex-end;
                            }

                            .author,
                            .date {
                                font-family: $main-font;
                                font-size: 1em;
                                color: #848eab;
                                font-weight: $light;
                            }
                        }
                        .description-content {
                            font-family: $main-font;
                            font-weight: $normal;
                            color: #848eab;
                            font-size: 1.5vw;
                            line-height: 2vw;
                            letter-spacing: -0.5px;

                            @media (min-width: 1250px) {
                                font-size: 1.11em;
                                line-height: 1.6em;
                            }

                            @media (max-width: $breakpoint-tablet) {
                                font-size: 20px;
                                font-size: 1.11em;
                                line-height: 1.75em;
                            }

                            .description-category {
                                border-bottom: 2px solid #5ebdcd;
                                font-size: 1em;
                                width: fit-content;
                            }

                            .learn-more {
                                font-family: $main-font;
                                font-weight: $normal;
                                color: #2e319c;
                                font-size: 1.5vw;
                                line-height: 2vw;
                                letter-spacing: -0.5px;

                                @media (min-width: 1250px) {
                                    font-size: 1.1em;
                                    line-height: 1.5em;
                                }

                                @media (max-width: $breakpoint-tablet) {
                                    font-size: 1.1em;
                                    line-height: 1.5em;
                                }

                                &:hover {
                                    font-weight: $bold;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
