@import "index";

.home {
    display: flex;
    flex-direction: column;

    .button-wrapper {
        width: 90%;
    }

    .hero-header-1 {
        display: flex;
        align-items: center;
        height: 70vh;
        min-height: 600px;

        @media (max-width: $breakpoint-mobile) {
            max-height: none;
            height: calc(100vh - 4.563em);
            min-height: 0px;
        }

        .hero-header-1-wrapper {
            width: 90%;
            display: flex;
            .hero-header-1-content {
                width: 52em;
                padding-bottom: 9em;
                margin-left: 3em;

                .header-title {
                    @media (max-width: $breakpoint-mobile) {
                        font-size: 3.5em;
                    }

                    @media (max-width: 360px) {
                        font-size: 3em;
                    }
                }

                .header-description {
                    padding-bottom: 0.5em;
                    line-height: 1.685em;
                }

                @media (max-width: $breakpoint-tablet) {
                    padding: 6em 0 3em 0;
                    margin-left: 0;
                    width: 95vw;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }

        .extra-info {
            padding-top: 10px;

            @media (max-width: $breakpoint-tablet) {
                width: 90%;
            }

            span {
                color: #fff;
                font-weight: $normal;
                font-size: 1.1em;
                letter-spacing: 0.375px;
                margin-right: 0.625em;
            }

            a {
                font-size: 1.1em;
                font-weight: $bold;
                text-decoration: underline;
            }

            a:visited {
                // color: #2e319c;
                //difficult to see with dark blue
                color: #fff;
            }

            a:hover {
                text-decoration: underline;
            }
        }
    }

    .panorama {
        width: 90%;
        align-self: center;
        display: flex;
        flex-direction: column;
        font-family: $main-font;
        margin-top: 70px;

        .pano-link {
            &:hover {
                transform: scale(1.01);
                transition: all 0.2s ease;
            }
        }

        h1 {
            text-align: center;
            font-weight: $lighter;
            font-size: 42px;
            letter-spacing: -1px;

            .logo {
                margin-right: 0.2em;
            }

            .first-word {
                font-weight: $extra-bold;
            }

            @media (max-width: $breakpoint-tablet) {
                font-size: 32px;
                padding-bottom: 10px;
            }
        }

        h3 {
            color: #6f708d;
            font-size: 20px;
            font-weight: $normal;
            letter-spacing: -0.25px;
            margin-top: -10px;
            text-align: center;
            padding-bottom: 3em;
        }
    }

    .supporters {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        width: 90%;

        .supporters-list {
            list-style-type: none;
            width: 100%;
            margin: 0;
            padding: 2em 0 6em 0;
            display: flex;
            justify-content: space-around;

            @media (max-width: $breakpoint-tablet) {
                // flex-direction: column;
                // align-items: center;
                // height: 40em;
                flex-wrap: wrap;
                width: 80%;
            }

            .supporter {
                flex: 0 1 auto;
                margin: 1em;
            }
        }
        .supporters-header {
            color: #191a2e;
            font-family: $main-font;
            font-size: 42px;
            font-weight: $extra-bold;
            letter-spacing: -0.95px;
            word-spacing: 2px;
            line-height: 64px;
            text-align: center;

            @media (max-width: $breakpoint-tablet) {
                font-size: 28px;
            }
        }
    }

    .advantages {
        display: flex;
        align-self: center;
        justify-content: space-between;
        width: 90%;
        padding: 4em 0 5em 0;

        @media (max-width: $breakpoint-tablet) {
            flex-direction: column;
            padding: 15px 0 20px 0;
        }

        .advantage {
            width: 430px;
            min-height: 252px;
            font-family: $main-font;

            @media (max-width: $breakpoint-tablet) {
                width: fit-content;
                min-height: fit-content;
                padding: 25px 0 25px 0;
            }

            svg {
                margin: 1em 0 1em 0.5em;
            }

            h3 {
                font-weight: $bold;
                font-size: 24px;
                line-height: 29px;
                letter-spacing: -1px;
                color: #191a2e;
            }

            span {
                font-weight: $normal;
                font-size: 18px;
                line-height: 26px;
                letter-spacing: -0.25px;
                color: #6f708d;
            }
        }
    }

    .hero-header-2 {
        background-color: $background-color;
        display: flex;
        align-items: center;
        justify-content: center;

        .hero-header-2-content {
            width: 900px;
            height: 60vh;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @media (max-width: $breakpoint-tablet) {
                max-height: 400px;
                width: 80%;
                height: fit-content;
                padding: 15vh 0 15vh 0;
            }

            .button-wrapper {
                height: fit-content;
                width: fit-content;

                @media (max-width: $breakpoint-mobile) {
                    height: 100%;
                    width: 100%;
                }
            }

            h1 {
                font-family: $main-font;
                color: #fff;
                font-weight: $bold;
            }
        }
    }
}

.testimonials {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: $main-font;
    margin: 30px;

    @media (max-width: $breakpoint-tablet) {
        margin: 20px 20px 0 20px;
    }

    h1 {
        width: 650px;
        margin: 50px;
        color: #191a2e;
        font-size: 42px;
        font-weight: $extra-bold;
        letter-spacing: -0.95px;
        line-height: 54px;
        align-self: center;

        @media (max-width: $breakpoint-tablet) {
            width: 80%;
            font-size: 32px;
            line-height: 40px;
        }
    }
}

.stories {
    width: 90%;
    align-self: center;
    text-align: center;
    padding-top: 2em;
    padding-bottom: 2em;

    @media (max-width: $breakpoint-tablet) {
        padding-top: 0;
    }
    .more-stories {
        color: #191a2e;
        font-family: $main-font;
        font-size: 42px;
        font-weight: $extra-bold;
        letter-spacing: -0.95px;
        line-height: 50px;
        margin: 25px;

        @media (max-width: $breakpoint-tablet) {
            font-size: 28px;
        }
    }

    .stories-content {
        display: flex;
        justify-content: space-around;
        text-align: left;

        @media (max-width: $breakpoint-mobile) {
            flex-direction: column;
            align-items: center;
        }

        .story {
            width: 100%;
            padding: 0.5em 1em 2em 1em;
            display: flex;
            flex-direction: column;

            @media (max-width: $breakpoint-mobile) {
                min-height: fit-content;
                height: fit-content;
                padding: 30px 0 30px 0;
                &:not(:last-child) {
                    border-bottom: 0.5px solid rgba(111, 112, 141, 0.6);
                }
            }

            .story-img {
                width: 100%;
                height: 30vw;
                max-height: 450px;
                background-size: cover;
                background-position: top;
                background-repeat: none;
                margin-bottom: 3.5em;

                @media (max-width: $breakpoint-mobile) {
                    height: 450px;
                }
            }

            .description {
                font-family: $main-font;
                font-size: 2vw;
                font-weight: $normal;
                letter-spacing: -0.25px;
                line-height: 2.7vw;
                color: #6f708d;

                @media (min-width: 1000px) {
                    font-size: 18px;
                    line-height: 26px;
                }

                @media (max-width: $breakpoint-mobile) {
                    font-size: 18px;
                    line-height: 26px;
                }
            }

            .category {
                font-family: $main-font;
                font-size: 2vw;
                font-weight: $bold;
                letter-spacing: -0.25px;
                line-height: 2.3vw;
                color: #6f708d;
                padding-bottom: 8px;
                border-bottom: 2px solid #5ebdcd;
                width: fit-content;

                @media (min-width: 1000px) {
                    font-size: 16px;
                    line-height: 19px;
                }

                @media (max-width: $breakpoint-mobile) {
                    font-size: 16px;
                    line-height: 19px;
                }
            }

            .title {
                font-family: $main-font;
                font-size: 2.5vw;
                font-weight: $bold;
                letter-spacing: -1.1px;
                line-height: 2.9vw;
                color: #191a2e;
                padding-top: 18px;

                @media (min-width: 1000px) {
                    font-size: 24px;
                    line-height: 30px;
                }

                @media (max-width: $breakpoint-mobile) {
                    font-size: 24px;
                    line-height: 30px;
                }
            }

            .learn-more {
                font-family: $main-font;
                font-size: 1.5vw;
                font-weight: $bold;
                letter-spacing: 0.82px;
                line-height: 2vw;
                color: #2e319c;

                @media (min-width: 1000px) {
                    font-size: 14px;
                    line-height: 16px;
                }

                @media (max-width: $breakpoint-mobile) {
                    font-size: 14px;
                    line-height: 16px;
                }

                &:hover {
                    font-weight: $extra-bold;
                }
            }
        }
    }
}
