$background-color: lightblue;
$main-font: "Raleway", sans-serif;
$secondary-font: "Source Sans Pro", sans-serif;

$breakpoint-tablet: 1000px;
$breakpoint-mobile: 700px;

$lighter: 300;
$light: 400;
$normal: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;

.header-title {
    font-family: $main-font;
    color: #fff;
    font-size: 4.35em;
    letter-spacing: -1.25px;
    line-height: 1em;
    font-weight: $extra-bold;
    padding-top: 1.5em;

    @media (max-width: $breakpoint-tablet) {
        width: 90%;
        font-size: 3.5em;
        line-height: 1.1em;
    }

    @media (max-width: $breakpoint-mobile) {
        font-size: 3em;
    }
}

.header-description {
    font-family: $main-font;
    color: #fff;
    font-size: 1.425em;
    letter-spacing: -0.25px;
    font-weight: $light;
    margin-top: -0.9375em;

    @media (max-width: $breakpoint-tablet) {
        width: 90%;
    }
}

.info-title {
    font-family: $main-font;
    color: #191a2e;
    font-size: 3em;
    font-weight: $extra-bold;
    letter-spacing: -1px;
    line-height: 1.3em;

    @media (max-width: $breakpoint-mobile) {
        font-size: 2.4em;
        line-height: 1.35em;
    }
}

.info-category {
    color: #191a2e;
    font-family: $main-font;
    font-size: 1em;
    font-weight: $extra-bold;
    letter-spacing: 0.75px;
    line-height: 1.25em;
    text-transform: uppercase;

    @media (max-width: $breakpoint-tablet) {
        font-size: 0.85em;
        line-height: 1.6em;
    }
}

.title {
    font-family: $main-font;
    font-size: 1.8em;
    font-weight: $bold;
    letter-spacing: -1.1px;
    line-height: 1.3em;
    color: #191a2e;
    padding-bottom: 0.5em;
}

.link {
    font-family: $main-font;
    font-size: 1.05em;
    font-weight: $normal;
    letter-spacing: -0.25px;
    line-height: 1.45em;
    word-spacing: 0.125em;
    text-decoration: underline;
    color: #2e319c;
}

.sub-title {
    font-family: $main-font;
    font-size: 1.6em;
    font-weight: $bold;
    letter-spacing: -1px;
    line-height: 1.3em;
    color: #696969;
    padding-bottom: 0.5em;
}

.text {
    font-family: $main-font;
    font-size: 1.3em;
    font-weight: $normal;
    letter-spacing: -0.25px;
    line-height: 1.45em;
    color: #6f708d;
    word-spacing: 0.125em;
}

.input-box {
    height: 100%;
    min-height: 3.425em;
    width: 100%;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(67, 76, 105, 0.15);
    border: 2px solid #fff;
    padding-left: 1.25em;
    margin-right: 0.625em;

    color: #848eab;
    font-family: $main-font;
    font-size: 1em;
    font-weight: $normal;
    letter-spacing: -0.5px;
    line-height: 1.875em;

    &:focus {
        outline: none;
        border: 2px solid #2e319c;
    }
}

.textarea-box {
    height: 100%;
    min-height: 9.375em;
    width: 100%;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(67, 76, 105, 0.15);
    border: none;
    padding-left: 1.25em;
    margin-right: 0.625em;

    color: #848eab;
    font-family: $main-font;
    font-size: 1em;
    font-weight: $normal;
    letter-spacing: -0.5px;
    line-height: 1.875em;

    &:focus {
        border: 1px solid #5ebdcd;
        outline: none;
        border: 2px solid #2e319c;
    }
}

.list {
    font-family: $main-font;
    font-size: 1.3em;
    font-weight: $normal;
    letter-spacing: -0.25px;
    line-height: 1.625em;
    color: #6f708d;
}

.list-rtl {
    font-family: $main-font;
    font-size: 1.3em;
    font-weight: $normal;
    letter-spacing: -0.25px;
    line-height: 1.625em;
    color: #6f708d;
    max-width: 30em;
    text-align: left;
    // direction: rtl;
}

.list-big {
    font-family: $main-font;
    font-size: 1.25em;
    font-weight: $normal;
    letter-spacing: -0.25px;
    line-height: 1.625em;
    color: #6f708d;
}

.error {
    color: red;
    padding: 1em 0 1em 0;
    font-size: 1em;
}

.word-blue {
    color: #2e319c;
}

.background-grey {
    background-color: #f9f8fd;
}
