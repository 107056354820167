@import "index";

.solutions {
    display: flex;
    flex-direction: column;
    align-items: center;

    .hero-header-1-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        .hero-header-1 {
            width: 90%;
            margin-top: 3.5em;
            height: 45vh;
            min-height: 28.6em;

            @media (max-width: $breakpoint-tablet) {
                height: fit-content;
            }

            @media (max-width: $breakpoint-mobile) {
                height: 250px;
                min-height: 200px;
                padding-top: 1em;
                display: flex;
                align-items: center;
            }

            .selector-wrapper {
                width: 90%;
                display: flex;
                flex-direction: column;
                padding-bottom: 7em;

                .solutions-title {
                    line-height: 0.8em;
                }

                .also-in-title {
                    padding: 0 0 0 0;
                    margin-top: -0.5em;
                    text-decoration: underline;
                }
                //not being used yet
                select {
                    width: 300px;
                    background-color: inherit;
                    outline: none;
                    border: none;
                    color: #2e319c;
                    font-size: 60px;
                    font-weight: $extra-bold;
                    letter-spacing: -1.25px;
                    text-decoration: underline;

                    @media (max-width: $breakpoint-mobile) {
                        font-family: $main-font;
                        font-size: 42px;
                        font-weight: $extra-bold;
                        letter-spacing: -1.1px;
                        line-height: 30px;
                        padding-top: 18px;
                        width: fit-content;
                    }
                }
            }
        }
    }
}
