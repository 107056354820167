@import "index";

.footer {
    background-color: #191a2e;
    color: #fff;
    font-family: $main-font;
    display: flex;
    flex-direction: column;

    .footer-content {
        display: flex;
        margin: 1.1em;
        height: 14.3em;
        justify-content: space-around;

        @media (max-width: $breakpoint-tablet) {
            padding: 8px;
            flex-direction: column;
            height: fit-content;
        }

        .footer-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 1em;
            font-weight: $normal;
            letter-spacing: 0.82px;
            width: 14.9em;

            @media (max-width: $breakpoint-tablet) {
                padding-top: 1em;
                width: 60%;
            }

            .logo-img {
                filter: grayscale(100%) invert(100%);
                margin-left: -2em;
                padding: 0 1.5em 1.5em 1.5em;
                height: auto;
                width: 17.8em;
            }

            .links {
                width: 9.35em;
                display: flex;
                justify-content: space-between;
                line-height: 2.9em;

                a {
                    color: #fff;
                    font-size: 0.85em;
                    font-weight: $normal;
                    letter-spacing: 0.7px;
                }
            }
        }

        .footer-about {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 19.5em;

            @media (max-width: $breakpoint-tablet) {
                width: 100%;
                padding-top: 1.8em;
            }

            .title {
                font-size: 1em;
                font-weight: bold;
                letter-spacing: 1px;
                color: #fff;
            }
            .content {
                font-size: 1em;
                font-weight: $light;
                letter-spacing: 0.82px;
            }
        }
    }

    .footer-nav {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-end;

        @media (max-width: $breakpoint-tablet) {
            align-items: flex-start;
            padding-top: 1.8em;
        }

        .links {
            display: flex;
            justify-content: space-between;
            width: 32.2em;

            @media (max-width: $breakpoint-tablet) {
                flex-direction: column;
                width: 100%;
                padding-bottom: 2.15em;
            }

            a {
                color: #fff;
                font-size: 1em;
                font-weight: bold;
                letter-spacing: 0.82px;
                text-align: right;

                @media (max-width: $breakpoint-tablet) {
                    text-align: left;
                    padding: 0.55em 0 0.55em 0;
                }
            }
        }

        .footer-media {
            width: 25%;
            display: flex;
            justify-content: space-between;
        }
    }

    .copyright {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        width: 90%;
        padding: 2.15em;
        align-self: center;
        display: flex;
        justify-content: space-between;

        @media (max-width: $breakpoint-tablet) {
            flex-direction: column;
            width: 100%;
        }

        span {
            font-size: 0.855em;
            font-weight: $normal;
            letter-spacing: 0.7px;
            color: #e2e2ff;
        }

        .copyright-links {
            // width: 429px;
            // width: 15em;
            display: flex;
            justify-content: flex-end;

            @media (max-width: $breakpoint-tablet) {
                flex-direction: column;
                height: fit-content;
                max-height: 130px;
                width: 100%;
                padding: 10px 0 10px 0;
            }
            a {
                font-size: 0.855em;
                letter-spacing: 0.7px;
                font-weight: $normal;
                color: #e2e2ff;
                padding: 0 1em 0 1em;

                @media (max-width: $breakpoint-tablet) {
                    padding: 0.5em 0 0.5em 0;
                }
            }
        }
    }
}
