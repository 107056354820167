@import "index";

.slider {
    .ant-carousel .slick-slide h3 {
        color: black;
    }
    .list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        margin: 0 auto 0 auto;

        @media (max-width: $breakpoint-tablet) {
            flex-wrap: wrap;
            width: 80%;
        }

        .active {
            border-bottom: 3px solid #5ebdcd;

            @media (max-width: $breakpoint-mobile) {
                border-bottom: none;
            }
        }

        .slider-img {
            @media (max-width: $breakpoint-mobile) {
                display: none;
            }
        }

        .slider-circle {
            display: none;
            @media (max-width: $breakpoint-mobile) {
                display: inline-block;
            }
        }

        li {
            cursor: pointer;
            margin: 2%;
            padding-bottom: 0.7em;
        }
    }

    .slider-carousel {
        .slider-content {
            width: 1363px;
            height: 725px;

            @media (max-width: $breakpoint-tablet) {
                height: fit-content;
                width: fit-content;
            }

            .slider-text-wrapper {
                .slider-text {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    text-align: left;
                    padding: 5em;
                    height: 100%;

                    h3,
                    h6 {
                        font-family: $secondary-font;
                        color: #fff;
                        text-shadow: 3px 3px 5px #191a2e;
                    }

                    h3 {
                        width: 18.3em;
                        height: fit-content;
                        font-size: 2em;
                        letter-spacing: -0.5px;
                        line-height: 1.36em;
                        font-weight: $normal;
                    }

                    h5 {
                        font-family: $main-font;
                        text-shadow: 3px 3px 5px #191a2e;
                        color: #fff;
                        font-size: 2em;
                        letter-spacing: -0.64px;
                        line-height: 0.9em;
                        font-weight: $light;
                    }

                    h6 {
                        font-size: 15px;
                        font-size: 1.105em;
                        letter-spacing: 0.05em;
                        line-height: 2.7em;
                        font-weight: $lighter;
                    }
                }
            }

            .mobile-slider-wrapper {
                display: none;
            }

            @media (max-width: $breakpoint-tablet) {
                .slider-text-wrapper {
                    display: none;
                }

                .mobile-slider-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    padding-top: 1.45em;

                    > * {
                        width: 90%;
                        padding-top: 1.45em;
                    }

                    .mobile-slider-text {
                        h3 {
                            font-family: $main-font;
                            width: 85vw;
                            height: fit-content;
                            font-size: 1.43em;
                            letter-spacing: -0.5px;
                            line-height: 1.5em;
                            font-weight: $lighter;
                            text-align: left;
                        }

                        .meta {
                            @media (max-width: $breakpoint-mobile) {
                                display: flex;
                                flex-direction: column;
                                // align-items: center;
                                // justify-content: space-between;
                            }
                            .meta-logo {
                                display: none;

                                @media (max-width: $breakpoint-mobile) {
                                    display: block;
                                }
                                .logo {
                                    height: 5em;
                                }
                            }

                            h5 {
                                color: #2e319c;
                                font-size: 2em;
                                letter-spacing: -0.64px;
                                padding-top: 3vh;
                                line-height: 1.1em;
                                font-weight: $light;
                                text-align: left;
                            }

                            h6 {
                                font-family: $main-font;
                                font-size: 1.09em;
                                letter-spacing: 0.055em;
                                line-height: 1.35em;
                                text-align: left;
                                font-weight: $lighter;
                            }
                        }
                    }
                }
            }
        }
    }
}
