@import "index";

.styledBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.3em;
    background-color: #2e319c;
    border: none;
    width: fit-content;
    white-space: nowrap;
    cursor: pointer;

    &.shadow {
        -webkit-box-shadow: 9px 7px 42px -10px rgba(0, 0, 0, 0.53);
        -moz-box-shadow: 9px 7px 42px -10px rgba(0, 0, 0, 0.53);
        box-shadow: 9px 7px 42px -10px rgba(0, 0, 0, 0.53);
    }

    @media (max-width: $breakpoint-tablet) {
        width: 100%;
    }

    &:hover {
        background-color: #3f43b6;
        transform: scale(1.01);
    }
}

.styledTxt {
    color: #fff;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 1em;
    letter-spacing: 0.071em;
    padding: 0.64em 1.15em 0.63em 1.15em;
}
