@import "index";

.contact {
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
        width: 90%;
    }

    .header-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 21.5em;

        @media (max-width: $breakpoint-tablet) {
            min-height: 12em;
        }

        .header {
            height: 50vh;
            width: 90%;
            margin-top: 3.5em;
            height: 45vh;
            min-height: 21.5em;

            @media (max-width: $breakpoint-tablet) {
                min-height: 21.5em;
                height: fit-content;
            }

            @media (max-width: $breakpoint-mobile) {
                height: 18em;
                min-height: 18em;
                align-items: center;
            }
        }
    }
    .content {
        display: flex;
        padding: 3em 0 0 0;

        @media (max-width: $breakpoint-tablet) {
            flex-direction: column;
        }

        .description {
            width: 70%;
            padding: 0 3em 2em 0;

            @media (max-width: $breakpoint-tablet) {
                width: 100%;
            }

            .talk-to-us {
                padding-bottom: 2em;
            }

            h5 {
                line-height: 1.65em;
            }
        }

        .contact-info {
            width: 30%;
            padding: 0;
            padding: 0 0 2em 3em;
            border-left: 1px solid grey;

            @media (max-width: $breakpoint-tablet) {
                width: 100%;
                border: none;
                padding: 0 0 0 0;
            }

            .media {
                display: flex;
                width: 40%;
                max-width: 9em;
                justify-content: space-between;
            }
        }
    }
    .contact-form-wrapper {
        margin: 3em 0 3em 0;

        label {
            padding: 0.5em 0 0.5em 0;
        }

        .title {
            align-self: flex-start;
        }

        .contact-form {
            display: flex;
            flex-direction: column;
            width: 100%;

            button {
                margin: 2em;
                align-self: flex-end;

                @media (max-width: $breakpoint-tablet) {
                    align-self: center;
                }
            }
        }
    }

    .email-confirmation {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80vh;
        h3 {
            color: #2e319c;
        }
    }
}
