@import "index";

.case-study {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .case-study-header-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (max-width: $breakpoint-tablet) {
            height: 21.5em;
        }
        .case-study-header {
            height: 50vh;
            min-height: 30em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 90%;

            .case-study-category {
                padding-bottom: 0.1em;
                border-bottom: 1.5px solid #5ebdcd;
                width: fit-content;
            }
        }
    }
    .case-study-content-wrapper {
        display: flex;
        width: 90%;
        .case-study-content {
            padding: 3em 0 5em 0;
            display: flex;
            flex-direction: column;

            .title {
                padding-top: 1em;
            }

            .image-wrapper {
                display: flex;
                width: 100%;
                justify-content: space-around;
                padding: 1.5em 0 1.5em 0;

                @media (max-width: $breakpoint-tablet) {
                    flex-direction: column;
                }

                .flex {
                    .image {
                        width: 100%;
                        height: auto;
                    }
                }

                .multi {
                    align-self: center;
                    justify-self: center;
                    .image {
                        width: 90%;
                        height: auto;
                    }
                }
            }

            .sub-title {
                padding-top: 1em;
            }
        }
    }
}
