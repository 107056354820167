@import "index";

.navbar {
    display: flex;
    align-items: center;
    width: 89%;
    min-height: 4.563em;
    justify-content: space-between;
    background-color: #fff;
    position: absolute;
    z-index: 999;
    align-self: center;

    @media (max-width: 1450px) {
        width: 100%;
    }

    @media (max-width: $breakpoint-mobile) {
        &.active {
            position: fixed;
        }
    }

    > .left {
        display: flex;
        align-items: center;
        width: 80%;
        min-width: 47em;

        .logo {
            .logo-img {
                margin-top: 0.25em;
                height: 2.5em;
                width: auto;
            }
        }

        > .nav-links {
            display: flex;
            justify-content: space-between;
            padding: 0 3em 0 3em;
            width: 100%;
            max-width: fit-content;
            height: 1.2em;
            justify-self: flex-start;

            > a,
            > div {
                text-decoration: none;
                font-family: $main-font;
                font-size: 1em;
                font-weight: $bold;
                letter-spacing: 0.82px;
                color: #2e319c;
                width: fit-content;
                margin: 0 2em 0 0.5em;
                height: 2em;
                cursor: pointer;

                > div {
                    margin-top: 1em;
                }

                a {
                    text-decoration: none;
                    font-family: $main-font;
                    font-size: 1em;
                    font-weight: $bold;
                    letter-spacing: 0.82px;
                    color: #2e319c;
                    width: fit-content;
                    margin: 0.5em 2em 0.1em 0.5em;
                    height: 2em;
                }
            }

            .active {
                border-bottom: 3px solid #5ebdcd;
            }

            .drop-links {
                display: flex;
                flex-direction: column;
                background-color: white;
                padding: 0.5em;
            }

            .show-dropdown {
                display: flex;
            }

            .hide-dropdown {
                display: none;
            }
        }
    }

    > .right {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 23%;
        min-width: 15.73em;
        max-width: 15.73em;

        > .account-tools {
            display: flex;
            justify-content: space-between;
            width: 132px;
            // width: 10em;
            height: 14px;
            // height: 1em;

            a {
                color: #6f708d;
                font-family: $main-font;
                font-size: 0.855em;
                line-height: 1.6em;
                font-weight: $bold;
                letter-spacing: 0.06em;
            }
        }
    }

    .mobile {
        display: none;
        width: 100%;
        background-color: #fff;

        @media (max-width: $breakpoint-mobile) {
            @keyframes show {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
            &.active {
                animation-name: show;
                animation-duration: 0.4s;
            }
        }

        > .dropdown-controller {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 4.563em;

            > .left {
                display: flex;
                align-items: center;
                width: 50%;

                .logo {
                    width: 100%;
                    .logo-img {
                        padding-left: 1.6em;
                        height: 2.5em;
                        width: auto;
                    }
                }
            }

            > .right {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 5%;
                margin-right: 2em;

                > .hamburger {
                    border: none;
                    background-color: inherit;
                    cursor: pointer;
                    outline: none;

                    .hamburger-img {
                        height: 1.8em;
                        width: 1.8em;
                        filter: invert(25%) sepia(100%) hue-rotate(190deg)
                            saturate(500%);
                    }

                    :visited {
                        border: none;
                    }
                }
            }
        }

        > .dropdown {
            display: flex;
            height: 0;
            overflow: hidden;

            &.active {
                display: flex;
                animation: expand 0.6s;
                height: fit-content;
            }
            > .nav-links {
                display: flex;
                flex-direction: column;
                width: 100%;

                > a {
                    font-family: $main-font;
                    line-height: 1.3em;
                    font-size: 1em;
                    font-weight: $normal;
                    letter-spacing: 0.06em;
                    color: #2e319c;

                    min-height: 3.58em;
                    padding: 1.1em;
                    border-bottom: 0.04em solid rgba(111, 112, 141, 0.6);
                }
                > div {
                    cursor: pointer;

                    h3 {
                        font-family: $main-font;
                        line-height: 1.3em;
                        font-size: 1em;
                        font-weight: $normal;
                        letter-spacing: 0.06em;
                        color: #2e319c;
                        // min-height: 3.58em;
                        margin: 1.1em;
                    }
                    // padding: 1.1em;
                    border-bottom: 0.04em solid rgba(111, 112, 141, 0.6);

                    .hide {
                        display: none;
                    }

                    .show {
                        display: block;
                    }

                    > div {
                        a {
                            font-family: $main-font;
                            line-height: 1.3em;
                            font-size: 1em;
                            font-weight: $normal;
                            letter-spacing: 0.06em;
                            color: #2e319c;
                            // min-height: 3.58em;
                            padding: 1.1em 0 1.1em 0;
                            padding-left: 4em;
                            border-top: 0.04em solid rgba(111, 112, 141, 0.6);
                        }
                        display: flex;
                        flex-direction: column;
                    }
                }
            }

            @keyframes expand {
                0% {
                    height: 0em;
                    opacity: 0;
                }

                60% {
                    height: 17.8em;
                    opacity: 0;
                }

                100% {
                    height: fit-content;
                    opacity: 1;
                }
            }
        }
    }

    @media (max-width: 1450px) {
        > .left {
            display: none;
        }
        > .right {
            display: none;
        }

        > .mobile {
            display: flex;
            flex-direction: column;
        }
    }
}
