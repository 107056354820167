@import "index";

.blue-overlay {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .overlay-img {
        position: absolute;
        background-size: cover;
        background-position: 0% 0%;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        filter: grayscale(100%) brightness(65%) contrast(75%) sepia(0.9)
            hue-rotate(145deg);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        top: 0;

        @media (min-width: 1300px) {
            // background-size: 1920px auto;
        }

        @media (max-width: $breakpoint-mobile) {
            background-position: 40% 40%;
        }

        &.center {
            background-position: 50% 40%;
        }

        &.flip {
            transform: scaleX(1);
        }
    }

    .overlay-children {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: center;
    }
}
