@import "index";

.user-confidentiality {
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
        width: 90%;
    }

    .header-wrapper {
        width: 100%;
        display: flex;
        height: 50vh;
        justify-content: center;
        align-items: center;
        background-color: lightblue;

        @media (max-width: $breakpoint-tablet) {
            height: 300px;
            padding-bottom: 1.5em;
            justify-content: center;
        }
        .header {
            width: 90%;
        }
    }

    > .content {
        padding: 5em 0 6em 0;
    }

    h3 {
        padding-top: 1em;
    }
    h5 {
        padding-bottom: 0.5em;
    }
}
