@import "index";

.solutions-template {
    display: flex;
    flex-direction: column;

    .text-wrapper {
        width: 90%;
        display: flex;
        flex-direction: column;
    }

    > div {
        padding: 5em 0 5em 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .benefit-1 {
        display: flex;

        &.text-wrapper {
            flex-direction: row;

            @media (max-width: $breakpoint-tablet) {
                flex-direction: column;
            }
        }

        @media (max-width: $breakpoint-tablet) {
            flex-direction: column;
            align-items: center;
        }

        .headline-1-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            @media (min-width: $breakpoint-tablet) {
                padding-right: 3vw;
            }

            @media (max-width: $breakpoint-tablet) {
                padding-bottom: 7vh;
            }

            .headline-1 {
                font-family: $main-font;
                font-weight: $extra-bold;
                color: #191a2e;
                font-size: 3em;
                letter-spacing: 1px;
                line-height: 1.3em;

                @media (max-width: $breakpoint-mobile) {
                    font-size: 2.43em;
                    letter-spacing: 0.7px;
                    line-height: 1.35em;
                    padding-bottom: 5vh;
                }
            }

            .email-form-content {
                display: flex;
                height: fit-content;
                align-items: center;
                width: 100%;

                @media (max-width: $breakpoint-tablet) {
                    flex-direction: column;
                    justify-content: center;
                }

                .input-wrapper {
                    width: 70%;
                    margin-right: 2em;
                    display: flex;
                    flex-direction: column;

                    @media (max-width: $breakpoint-tablet) {
                        margin-right: 0;
                        width: 100%;
                    }

                    .email-input {
                        &:focus {
                            outline: none;
                            border: 2px solid #2e319c;
                        }

                        @media (max-width: $breakpoint-tablet) {
                            width: 100%;
                            margin-bottom: 1.5em;
                        }
                    }
                }
                .button-wrapper {
                    height: fit-content;
                    width: fit-content;

                    .bigBtn {
                        height: 3.5em;
                    }

                    @media (max-width: $breakpoint-tablet) {
                        height: 100%;
                        width: 100%;
                    }
                }
            }
            .email-confirmation {
                padding-top: 3em;
                height: 10vh;
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                    color: #2e319c;
                }
            }
        }

        .info-1 {
            width: 100%;

            h5 {
                font-family: $main-font;
                font-size: 1.29em;
                font-weight: $normal;
                letter-spacing: -0.25px;
                line-height: 1.45em;
                color: #848eab;
                padding-bottom: 3vh;

                @media (max-width: $breakpoint-tablet) {
                    padding-bottom: 6vh;
                }
            }

            h3 {
                font-family: $main-font;
                font-size: 1.71em;
                font-weight: $extra-bold;
                letter-spacing: -1px;
                line-height: 1.25em;
            }

            .benefits {
                list-style-type: none;
                margin: 0;
                padding: 0;
                -webkit-columns: 2;
                -moz-columns: 2;
                columns: 2;
                height: fit-content;

                @media (max-width: $breakpoint-tablet) {
                    padding-top: 3vh;
                    columns: 1;
                }

                li {
                    list-style-position: inside;
                    -webkit-column-break-inside: avoid;
                    page-break-inside: avoid;
                    break-inside: avoid;
                    display: flex;
                    color: #191a2e;
                    font-family: $main-font;
                    font-size: 1em;
                    font-weight: $extra-bold;
                    letter-spacing: -1px;
                    line-height: 2.2em;
                    text-transform: uppercase;
                    width: 100%;

                    svg {
                        margin-right: 0.5em;
                    }
                    @media (max-width: $breakpoint-tablet) {
                        padding: 0.6em 0 0.6em 0;
                    }
                }
            }
        }
    }

    .clinics-ad {
        display: flex;
        flex-direction: column;

        h3 {
            font-family: $main-font;
            font-size: 1.72em;
            font-weight: $extra-bold;
            letter-spacing: -1px;
            line-height: 1.25em;
            text-align: center;
            width: 95%;
        }

        .logo-list {
            display: flex;
            list-style-type: none;
            justify-content: space-around;
            align-items: center;
            margin: 0;
            width: 90%;
            padding: 4em 0 0 0;

            @media (max-width: $breakpoint-tablet) {
                flex-wrap: wrap;
                width: 70%;
            }

            .supporter {
                flex: 0 1 auto;
                margin: 1em;
            }
        }
    }

    .benefit-2 {
        width: 100%;
        align-self: center;

        .info-2 {
            width: 66%;

            @media (max-width: $breakpoint-tablet) {
                width: 100%;
            }

            .sub-description-title {
                color: #6f708d;
                font-family: $main-font;
                font-size: 1.28em;
                font-weight: $bold;
                letter-spacing: -0.25px;
                line-height: 1.5em;
                padding-top: 1.5em;
            }

            .sub-description {
                color: #848eab;
                font-family: $main-font;
                font-size: 1.28em;
                font-weight: $normal;
                letter-spacing: -0.25px;
                line-height: 1.5em;
            }
        }
    }

    .benefit-3 {
        width: 100%;
        align-self: center;

        .info-3 {
            width: 66%;
            align-self: flex-end;
            justify-self: flex-end;
            text-align: right;

            @media (max-width: $breakpoint-tablet) {
                text-align: left;
                align-self: center;
                justify-self: center;
                width: 100%;
            }

            .sub-description-title {
                color: #6f708d;
                font-family: $main-font;
                font-size: 1.28em;
                font-weight: $bold;
                letter-spacing: -0.25px;
                line-height: 1.5em;
                padding-top: 1.5em;
            }

            .text {
                color: #848eab;
            }
        }
    }

    .quote {
        width: 70%;
        align-self: center;
        h1 {
            color: #2e319c;
            font-family: $main-font;
            font-weight: $light;
            font-size: 3.42em;
            letter-spacing: -0.86px;
            line-height: 1.23em;

            @media (max-width: $breakpoint-tablet) {
                font-size: 2.14em;
                letter-spacing: -0.3px;
                line-height: 1.53em;
                font-weight: $lighter;
                text-align: center;
            }
        }

        h3 {
            color: #191a2e;
            font-family: $main-font;
            font-weight: $light;
            font-size: 2em;
            letter-spacing: -0.64px;
            line-height: 1.35em;

            @media (max-width: $breakpoint-tablet) {
                font-size: 1.79em;
                letter-spacing: -0.5px;
                text-align: center;
            }
        }
    }

    .hero-header {
        // background-color: $background-color;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0 0 0;
        margin-top: 5em;

        .hero-header-content {
            height: 60vh;
            width: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @media (max-width: $breakpoint-tablet) {
                max-height: 28.5em;
                width: 89%;
                height: fit-content;
                padding: 15vh 0 15vh 0;
            }

            .button-wrapper {
                height: fit-content;
                width: fit-content;

                @media (max-width: $breakpoint-mobile) {
                    height: 100%;
                    width: 100%;
                }
            }

            h1 {
                font-family: $main-font;
                color: #fff;
                font-weight: $bold;
            }
        }
    }
}
